import React from 'react';
import config from '../../config';
export default function Footer() {
  return (
    <section id="footer">
      <div className="inner">
        <ul className="contact">
          {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <li key={url}>
                <a href={url} className={`fab ${icon}`}></a>
              </li>
            );
          })}
        </ul>
        <ul className="copyright">
          <li>&copy; Julien Topçu. All rights reserved.</li>
          <li>
            Design: <a href="http://html5up.net">HTML5 UP</a>
          </li>
        </ul>
      </div>
    </section>
  );
}
