module.exports = {
  siteTitle: 'Julien Topçu', // <title>
  manifestName: 'JulienTopcu.com',
  manifestShortName: 'JulienTopcu', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#003e77',
  manifestThemeColor: '#003e77',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  //pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Julien Topçu',
  subHeading: 'Technical Coach, Blogger, Public Speaker, OWASP Fundation Member, CraftsRecords Founder',
  // social
  socialLinks: [
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/julientopcu',
    },
    {
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/julien-top%C3%A7u/',
    },
    {
      icon: 'fa-youtube',
      name: 'YouTube',
      url: 'https://www.youtube.com/channel/UCk_NTkdht8oiDfy2cK_O_lQ',
    },
    {
      icon: 'fa-gitlab',
      name: 'GitLab',
      url: 'https://gitlab.com/jtopcu',
    },
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/julien-topcu',
    }
  ],
};
